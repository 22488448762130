import { getUnderlyings, getQuotationIssuers } from "@/api/quotation";
import { getBuyerInstitutionInfo, getInstitutionUserInfo, getUserStatus, getIndividualUserInfo } from "@/api/user";
import { getNewsClassifications } from "@/api/news";
import { getSubscribeList } from "@/api/subscribe";
import { getProductsConfig } from "@/api/market";
// 获取全量标的库
export const updateUnderlyings = (isFetchingUnderlyings) => {
  return async (dispatch) => {
    if (isFetchingUnderlyings) return;
    dispatch({
      type: "IS_FETCHING_UNDERLYINGS",
      isFetchingUnderlyings: true,
    });
    try {
      const { products } = await getUnderlyings();
      const mapping = {};
      products.forEach((p) => {
        mapping[p.id] = p;
      });
      dispatch({
        type: "UPDATE_UNDERLYINGS",
        underlyings: mapping,
      });
    } catch (e) {
      dispatch({
        type: "UPDATE_UNDERLYINGS",
        underlyings: {},
      });
    }
  };
};
// 获取所有的issuer
export const updateIssuers = (isFetchingIssuers) => {
  return async (dispatch) => {
    if (isFetchingIssuers) return;
    dispatch({
      type: "IS_FETCHING_ISSUERS",
      isFetchingIssuers: true,
    });
    try {
      const { issuer, custodian } = await getQuotationIssuers();
      const mapping = {};
      const custodiansMap = {};
      issuer.forEach((issuer) => {
        mapping[issuer.id] = issuer;
      });
      const custodianNew = custodian.sort((a, b) => {
        if (!a.shortName || !b.shortName) return;
        const aName = a.shortName.toLocaleUpperCase();
        const bName = b.shortName.toLocaleUpperCase();
        return aName.charCodeAt(0) - bName.charCodeAt(0);
      });
      custodianNew.forEach((c) => {
        custodiansMap[c.id] = c;
      });
      dispatch({
        type: "UPDATE_ISSUERS",
        issuers: mapping,
        custodians: custodiansMap,
      });
    } catch (e) {
      dispatch({
        type: "UPDATE_ISSUERS",
        issuers: {},
        custodians: {},
      });
    }
  };
};

// update buyer institution info
export const updateBuyerInstitutionInfo = (isFetchingInstitutionInfo) => {
  return async (dispatch) => {
    if (isFetchingInstitutionInfo) return;
    dispatch({
      type: "IS_FETCHING_INSTITUTION_INFO",
      isFetchingInstitutionInfo: true,
    });
    try {
      const { commercialInfo, generalInfo } = await getBuyerInstitutionInfo();
      dispatch({
        type: "UPDATE_INSTITUTION_INFO",
        institutionInfo: commercialInfo,
        generalInfo,
      });
    } catch (e) {}
  };
};
// update buyer institution user info
export const updateInstitutionUserInfo = (isFetchingInstitutionInfo, params) => {
  return async (dispatch) => {
    if (isFetchingInstitutionInfo) return;
    dispatch({
      type: "IS_FETCHING_INSTITUTION_USER_INFO",
      isFetchingInstitutionInfo: true,
    });
    try {
      const res = await getInstitutionUserInfo({ userAccountId: params });
      dispatch({
        type: "UPDATE_INSTITUTION_USER_INFO",
        institutionUserInfo: res,
      });
    } catch (e) {}
  };
};

// update individual status
export const updateIndividualStatus = () => {
  return async (dispatch) => {
    try {
      const { status } = await getUserStatus();
      dispatch({
        type: "UPDATE_INDIVIDUAL_STATUS",
        status,
      });
    } catch (e) {}
  };
};
// update individual userInfo
export const updateIndividualUserInfo = (params) => {
  return async (dispatch) => {
    try {
      const res = await getIndividualUserInfo(params);
      dispatch({
        type: "UPDATE_INDIVIDUAL_USERINFO",
        individualUserInfo: res,
      });
    } catch (e) {}
  };
};

// 获取资讯平台获取定义
export const updateNewsClassifications = ({ params }) => {
  return async (dispatch) => {
    try {
      const classifications = await getNewsClassifications(params);
      dispatch({
        type: "UPDATE_NEWS_CLASSIFICATIONS",
        classifications,
      });
    } catch (e) {}
  };
};
// 获取产品配置
export const updateProductsConfig = ({ isFetchingProductsConfig }) => {
  return async (dispatch) => {
    if (isFetchingProductsConfig) return;
    dispatch({
      type: "IS_FETCHING_PRODUCTS_CONFIG",
      isFetchingProductsConfig: true,
    });
    try {
      const { config } = await getProductsConfig();
      dispatch({
        type: "UPDATE_PRODUCTS_CONFIG",
        productsConfig: JSON.parse(config),
      });
    } catch (e) {
      dispatch({
        type: "UPDATE_PRODUCTS_CONFIG",
        productsConfig: {},
      });
    }
  };
};
