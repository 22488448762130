import request from '@/utils/request';


// 订阅方案
export async function getSubscribeList() {
  return await request({
    url: "/individual/subscription/program/list",
    responseType: "SubscriptionProgramListResp",
  });
}

// 记录安卓用户的点击
export async function fetchAndroidRecord() {
  return await request({
    url: "/individual/subscription/recordAndroidUser",
  });
}

// 获取订阅nacos配置
export async function fetchPlanConfig() {
  return await request({
    url: "/individual/app/getPlanConfig",
    responseType: 'PlanConfigRes'
  });
}
